import FormButton from '@/components/Form/FormButton'
import FormGroup from '@/components/Form/FormGroup'
import FormInput from '@/components/Form/FormInput'
import FormText from '@/components/Form/FormText'
import FormTextRequire from '@/components/Form/FormTextRequire'
import FormView from '@/components/Form/FormView'
import { LoginSlider } from '@/components/LoginSlider'
import { SideBarBanner } from '@/components/SideBarBanner'
import { BannerType } from '@/constants'
import { useNotification } from '@/containers'
import { useCookie } from '@/hooks/useCookie'
import HeaderNoMenu from '@/layouts/HeaderNoMenu'
import { type BannerList } from '@/types'
import { api } from '@/utils'
import { setContext } from '@/utils/api-interceptors'
import { getBannerSSR, getConfig } from '@/utils/endpoint'
import { translateSchema } from '@/utils/validations'
import clsx from 'clsx'
import { getCookies } from 'cookies-next'
import { useFormik } from 'formik'
import { type GetServerSideProps } from 'next'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

type LoginProps = {
  banner: BannerList[]
}

const Login = ({ banner }: LoginProps) => {
  const { systemConfig } = useNotification()
  const t = useTranslations('Login')
  const [, setPhoneNumber] = useCookie('phoneNumber', {})
  const [, setRequestId] = useCookie('requestId', {})
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [checkRedirectUrl, setCheckRedirectUrl] = useState(false)
  const router = useRouter()
  const onSubmitFn = () => {
    setIsSubmitted(true)
  }
  useEffect(() => {
    if (router.query.redirect) {
      return setCheckRedirectUrl(true)
    }
    return
  }, [router])

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: translateSchema('login', t),

    onSubmit: async values => {
      if (!window.grecaptcha) {
        console.error('reCAPTCHA not yet loaded')
        return
      }
      const tokenReCaptcha = await window.grecaptcha.execute(
        process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string,
        { action: 'login' }
      )

      if (tokenReCaptcha) {
        setErrorMessage('')
        setIsProcessing(true)
        const res = await api(`api/auth/login/request-otp`, {
          method: 'POST',
          baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL,
          headers: { recaptcha: tokenReCaptcha },
          data: values,
        })

        if (res?.message) {
          setErrorMessage(res.message)
          setIsProcessing(false)
          return
        }
        setPhoneNumber(values.phone)
        setIsProcessing(false)
        setRequestId(res.data.requestId)
        const redirectUrl = router.query.redirect as string
        const voucher = router.query.voucher as string
        let nextUrl = '/otp'

        if (checkRedirectUrl) {
          nextUrl += `?redirect=${redirectUrl}`
          if (voucher) {
            nextUrl += `&voucher=${voucher}`
          }
        }
        router.push(nextUrl)
      }
    },
  })

  return (
    <>
      <style global jsx>{`
        body::-webkit-scrollbar {
          display: none;
        }
      `}</style>
      <HeaderNoMenu />
      <main>
        {' '}
        <SideBarBanner className='top-[37%]'></SideBarBanner>
        <div className='h-[calc(100vh - 129px)] no-scrollbar m-auto flex max-w-[1200px] items-center gap-x-[210px] pt-[5px]'>
          <div className='flex w-full flex-2 flex-col'>
            <div className='text-HeadlineLarge font-bold text-dark-400'>
              {t('loginAccount')}
            </div>
            <div className='mt-[40px]'>
              <FormView
                onSubmit={formik.handleSubmit}
                buttonClassName='mt-[24px]'
              >
                <FormGroup className='flex w-full flex-col'>
                  <FormText className='mb-1 text-left text-fieldInput font-bold'>
                    {t('phone')}
                  </FormText>
                  <FormInput
                    name='phone'
                    placeholder={t('phonePld')}
                    value={formik.values.phone.trim()}
                    isError={isSubmitted && formik.errors.phone}
                    autoComplete='off'
                    onChange={e => {
                      formik.handleChange({
                        target: {
                          value: e.target.value.trim().replace(/\D/, ''),
                          name: 'phone',
                        },
                      })
                      setErrorMessage('')
                    }}
                    alert
                    className={clsx(
                      isSubmitted &&
                        formik.errors.phone &&
                        'border border-red-600',
                      ' !h-12 w-[380px] min-w-[360px] rounded border border-solid border-grey-500 text-left text-fieldInput transition placeholder:text-dark-400  placeholder:opacity-70 hover:cursor-pointer'
                    )}
                  ></FormInput>
                  {isSubmitted && formik.errors.phone ? (
                    <FormTextRequire className='pt-2 text-fieldInput text-sm font-normal text-red-500'>
                      {formik.errors.phone}
                    </FormTextRequire>
                  ) : (
                    ''
                  )}
                  {errorMessage && isSubmitted ? (
                    <FormTextRequire className='border-none pl-0 pt-2 text-fieldInput text-sm  text-red-500'>
                      {errorMessage}
                    </FormTextRequire>
                  ) : (
                    <></>
                  )}
                </FormGroup>
                <FormButton
                  isProcessing={isProcessing}
                  className={clsx(
                    formik.dirty &&
                      ' !bg-product-salePrice text-Headline  !text-white-400',
                    'disabled:text-[rgb(0 0 0 / 25%)] h-[48px] w-full rounded border bg-grey p-[10px] font-semibold uppercase text-black-400/25 disabled:bg-grey disabled:text-black-400/25 disabled:outline-none'
                  )}
                  onClick={onSubmitFn}
                  disabled={isProcessing}
                >
                  {t('requestOtp')}
                </FormButton>
              </FormView>
            </div>
            <span className='mt-[32px] text-center'>
              <span className='text-dark-400 opacity-70'>
                {' '}
                {t('justKnow', { brand: systemConfig?.websiteBrandName })}{' '}
              </span>
              <Link
                className=' text-fieldInput font-normal text-blue'
                href='/register'
              >
                {t('registerNow')}
              </Link>
            </span>
          </div>
          <div className='my-auto ml-auto min-h-[700px] min-w-[538px] py-[60px]'>
            <LoginSlider
              sliders={
                banner.find((e: BannerList) => e.type === BannerType.LOGIN)
                  ?.items
              }
            ></LoginSlider>
          </div>
        </div>
      </main>
    </>
  )
}

export default Login

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  locale,
}) => {
  const { token, language, messages } = await setContext(
    getCookies({ req, res }),
    {
      language: locale,
    }
  )
  if (token) {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
    }
  }
  const [banner, systemConfig] = await Promise.all([
    getBannerSSR(),
    getConfig(),
  ])

  return {
    props: {
      systemConfig,
      messages,
      banner,
      language,
      token: token || null,
    },
  }
}
